import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/xb7tLEAwCiw">
    <SEO title="Drifting from Jesus - Hebrews" />
  </Layout>
)

export default SermonPost
